<template>
  <dialog-outline v-model="showDialog">
    {{ dialogTitle }}
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <template v-slot:pre-context>
      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>
    </template>

    <!-- context -->
    <template v-slot:context>
      <div
        style=" display: flex;
  align-items: center;"
        class="pa-3"
      >
        <v-icon :color="titleColor" left>{{ iconProject }}</v-icon>
        <h3 :class="titleClass">{{ project?.projectName }}</h3>
      </div>

      <v-divider></v-divider>

      <v-container>
        <!-- Project Manager -->
        <v-select
          v-model="selectedManager"
          class="mt-2"
          dense
          item-text="name"
          item-value="id"
          :items="managerList"
          :label="managerLabel"
          :hint="managerHint"
          :rules="managerSelectionRequired"
        ></v-select>

        <!-- Comments -->
        <v-textarea
          hide-details
          :label="commentLabel"
          v-model="assignmentComment"
          outlined
        ></v-textarea>

        <!-- Notification Email -->
        <v-checkbox
          dense
          hide-details
          v-model="notifyUser"
          :label="notificationLabel"
        ></v-checkbox>
      </v-container>
    </template>

    <!-- Dialog Actions -->
    <template v-slot:actions>
      <base-button @click="onOk" :disabled="isManagerEmpty">
        {{ dialogOkCommandLabel }}
      </base-button>
    </template>

    <!-- Cancel Action -->
    <template v-slot:actionCancel>
      <base-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCancelCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
// vuex
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("project");

// design
import { iconProjects } from "@/design/icon/iconConst";

// model
import {
  findProjectOperation,
  projectOperation
} from "@/model/workflow/project/projectModel";

// mixins
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";
import { baseComponentMixin } from "@/mixins/shared/base/component/baseComponentMixin";

//model
import { actionResultType } from "@/model/action/actionModel";
import { displayTypes, fontEmphasis, Text } from "@/design/text/Text";
import {
  Color,
  colorMD,
  variantNumber,
  variantType
} from "@/design/colors/Color";

export default {
  name: "AssignManagerDialog",
  mixins: [dialogOutlineMixin, baseComponentMixin],
  props: {
    /**
     * @type {{projectId: Number, projectName:string, creationDate: string, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, recordId: Number, subject: String, owner: String, userName:string, projectManagerId: Number, templateId: Number, templateName: String, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean, comment:string, durableInstanceUid:string, creationDate:string, userTasks: {taskId: number, typeId: number, actorId: number, actorName: string, userName: string, assignee: string, canReassign: boolean, canceledRecipients: string, comment: string, dueDate: string, duration: string, isDue: boolean, isMilestone: boolean, isOverdue: boolean, priority: number, recordId: number, recordName: string, status: number, statusText: string, notes: {id: number, created: string, taskId: number, userId: number, userName: string}[]}}}
     */
    project: undefined,
    assignEvent: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      iconProject: iconProjects,
      titleClass: new Text(
        new Color(colorMD.grey, variantType.darken, variantNumber.n3),
        displayTypes.subtitle1,
        fontEmphasis.regular
      ).getClassText(),
      titleColor: colorMD.indigo,
      selectedManager: undefined,
      managerList: [],
      notifyUser: false,
      assignmentComment: ""
    };
  },

  computed: {
    /**
     * Assign Operation
     * @return {{name: string, icon: string, label: string}} Assign Operation
     */
    operation() {
      return findProjectOperation(projectOperation.ReassignManager);
    },

    /**
     * Assign operation Label
     * @return {string} Assign operation Label
     */
    operationLabel() {
      return this.operation?.label;
    },

    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.operation?.icon;
    },

    /**
     * Get Assign Manager Dialog title
     * @return {string} Assign Manager Dialog title
     */
    dialogTitle() {
      return `${this.operationLabel}`;
    },

    /**
     * dialog Ok Command Label
     * remarks: overwrite modalDialogMixin.dialogOkCommandLabel
     * @return {string} OK command Label
     */
    dialogOkCommandLabel() {
      return "Assign";
    },

    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return `${this.operationLabel}'`;
    },

    /**
     * User Notification Label
     * @return {string}
     */
    notificationLabel() {
      return "Send Task Assignment Notification";
    },

    /**
     * Manager Label
     * @return {string}
     */
    managerLabel() {
      return "Manager";
    },

    /**
     * Comment Label
     * @return {string}
     */
    commentLabel() {
      return "Comment";
    },

    /**
     * Manager Hint
     * @return {string}
     */
    managerHint() {
      return "Select Project Manager";
    },

    /**
     * Compute if Manager Selected
     * @return {boolean}
     */
    isManagerEmpty() {
      return !this.selectedManager;
    },

    /**
     * Manager Selection Required Rule(
     * @return {(function(*=))[]}
     */
    managerSelectionRequired() {
      return [v => !!v || "Project Manager is required"];
    }
  },

  methods: {
    ...mapActions({
      /**
       * get Workflow Project Manager Candidates
       */
      getManagerCandidates: "getManagerCandidates"
    }),

    /**
     * Performs Assign Manager
     */
    async onOk() {
      try {
        this.clearAlert();
        const managerName = this.managerList.find(
          user => user.id === this.selectedManager
        ).name;

        const result = await this.assignEvent(
          managerName,
          this.selectedManager,
          this.assignmentComment,
          this.notifyUser
        );
        if (result?.type === actionResultType.success) {
          await this.autoCloseDialog(result.message);
        } else {
          this.internalAlert = this.createAlert(
            this.alertTypeName.error,
            this.formatAlertError(this.action, result.message),
            false
          );
        }
      } catch (e) {
        this.internalAlert = this.createAlertError(
          this.formatAlertError(this.action, e),
          false
        );
      }
    },

    /**
     * Populate Manager List
     */
    async populateManagerList() {
      try {
        if (this.project) {
          await this.getManagerCandidates(this.project.templateId).then(
            response => {
              this.managerList = response?.data;
            }
          );

          this.selectedManager = this.managerList.find(
            user => user.id === this.project.projectManagerId
          );
        }
      } catch (e) {
        this.internalAlert = this.createAlertError(
          this.formatAlertError(this.action, e),
          false
        );
      }
    }
  },

  async mounted() {
    //Populate List when component Loaded
    await this.populateManagerList();
  },

  watch: {
    /**
     * Watcher on Project Changed
     */
    async project() {
      await this.populateManagerList();

      this.notifyUser = false;
      this.assignmentComment = "";
    }
  }
};
</script>
